import React from "react";
import {useMsal} from "@azure/msal-react";
import {Link} from "react-router-dom";

function handleLogout(instance) {
    instance.logoutRedirect().catch(e => console.error(e));
}

export const SignOutButton = () => {
    const {instance} = useMsal();

    return (
        <Link className="nav-link" to="#" onClick={() => handleLogout(instance)}>Uitloggen</Link>
    );
}
