import React, {useCallback} from "react";
import {DefaultFullPageLoader} from "components/FullPageLoader/DefaultFullPageLoader";

export const LocationsTableCard = ({locations, title, subTitle, loader}) => {
    const createLocationRow = useCallback((location) =>
        <tr key={location.id}>
            <td>{location.parentName}</td>
            <td>{location.name}</td>
        </tr>, []);

    return (
        <div className="card big-shadow h-100">
            {loader && <DefaultFullPageLoader/>}
            <div className="card-header border-0 pb-0">
                <h5 className="card-title d-flex align-items-center">
                    <span className="text-dark" style={{fontWeight: 600}}>{title}</span>
                    <span className="text-muted ms-2" style={{fontSize: ".8rem"}}>{subTitle}</span>
                </h5>
            </div>
            <div className="card-body pt-0" style={{overflowY: "scroll"}}>
                <table className="table table-hover table-sm table-responsive">
                    {locations.length === 0 &&
                    <caption className="text-center">Geen clienten gevonden</caption>}
                    <thead>
                    <tr className="text-muted" style={{fontWeight: 600}}>
                        <th>Locatie</th>
                        <th>Kamer</th>
                    </tr>
                    </thead>
                    <tbody>
                    {locations.map(client => createLocationRow(client))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}