import {Link} from "react-router-dom";
import React, {useEffect, useMemo} from "react";
import moment from "moment-timezone";

export const SubLocationButton = ({subLocation}) => {
    useEffect(() => console.log(subLocation));
    const max = useMemo(() => subLocation.statistics.totalRooms, [subLocation]);
    const totalBookings = useMemo(() => subLocation.statistics.totalBookings, [subLocation]);
    const percentage = useMemo(() => max === 0 ? 100 : totalBookings / max * 100, [max, totalBookings]);
    const nextAvailableDate = useMemo(() =>
            subLocation.statistics.nextAvailableDate ?
                moment(subLocation.statistics.nextAvailableDate).add(1, "days").format("DD-MM-YYYY") : "?",
        [subLocation]);
    const bgClass = useMemo(() => {
        let tmp = percentage < 100 ? "bg-danger" : percentage === 100 ? "bg-success" : "bg-info";
        if (max !== 0 && percentage < 100) tmp += " progress-bar-striped progress-bar-animated";

        return tmp
    }, [percentage, max]);
    const totalReservations = useMemo(() => subLocation.statistics.totalReservations, [subLocation]);
    const rPercentage = useMemo(() => max === 0 ? 0 : totalReservations / max * 100, [max, totalReservations]);

    return (
        <div className="col mb-3">
            <div className="card border-0">
                <div className="card-body p-0">
                    <Link className="btn btn-outline-info btn-block py-3 w-100" role="button" to={`/${subLocation.id}`}>
                        <div style={{fontWeight: 600}}>{subLocation.name}</div>
                        <div className="d-flex flex-column">
                            <div className="d-flex justify-content-between" style={{fontSize: ".8rem"}}>
                                <span>Capaciteit: {max}</span>
                                <span>{totalBookings >= max ? `Weer beschikbaar: ${nextAvailableDate}` : ""}</span>
                            </div>
                            <div className="progress w-100">
                                <div className={`progress-bar ${bgClass}`} role="progressbar"
                                     style={{width: `${percentage}%`, opacity: "0.7"}}>
                                    {max !== 0 ? totalBookings : ""}
                                </div>
                                <div className={`progress-bar bg-warning progress-bar-striped progress-bar-animated`}
                                     role="progressbar" style={{width: `${rPercentage}%`, opacity: "0.7"}}>
                                    {max !== 0 ? totalReservations : ""}
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    )
}