import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import moment from "moment-timezone";
import {Modal} from "bootstrap";
import Timeline, {
    DateHeader,
    SidebarHeader,
    TimelineHeaders,
    TimelineMarkers,
    TodayMarker
} from "react-calendar-timeline";
import {find, keys, values} from "lodash";
import {ChoiceModal} from "components/Modal/ChoiceModal";
import {DefaultFullPageLoader} from "components/FullPageLoader/DefaultFullPageLoader";
import "react-calendar-timeline/lib/Timeline.css";
import "./CalendarCard.scss";

export const minTime = moment().startOf("day").dayOfYear(1).subtract(1, "years").valueOf();
export const maxTime = moment().startOf("day").add(1, "years").valueOf();
export const day = 24 * 60 * 60 * 1000;

let _ID = -1;

function limitTimeline(visibleTimeStart, visibleTimeEnd, updateScrollCanvas) {
    if (visibleTimeStart < minTime && visibleTimeEnd > maxTime) updateScrollCanvas(minTime, maxTime)
    else if (visibleTimeStart < minTime) updateScrollCanvas(minTime, minTime + (visibleTimeEnd - visibleTimeStart))
    else if (visibleTimeEnd > maxTime) updateScrollCanvas(maxTime - (visibleTimeEnd - visibleTimeStart), maxTime)
    else updateScrollCanvas(visibleTimeStart, visibleTimeEnd)
}

function groupRenderer({group}) {
    return <>{group.title} ({group.capacity})</>;
}

function itemRenderer({item, itemContext, getItemProps}) {
    const itemProps = getItemProps(item.itemProps);
    itemProps.className = itemProps.className + "rounded-pill " +
        (!item.isBooking ? "bg-warning" : item.isCrisis ? "bg-danger" : "bg-primary");
    itemProps.style.border = null;
    itemProps.style.opacity = 0.8;

    return (
        <div {...itemProps}>
            <div className="rct-item-content" style={{maxHeight: `${itemContext.dimensions.height}`}}>
                {itemContext.title}
            </div>
        </div>
    );
}

function findIsCrisis(client, booking, today) {
    const periods = client.careOrderProducts?.filter(cOP => cOP.isCrisis &&
        (booking.endDate ? cOP.beginDate <= booking.endDate : true) &&
        (cOP.endDate ? cOP.endDate >= booking.beginDate : true))
        .map(cOP => ({beginDate: cOP.beginDate, endDate: cOP.endDate}));

    if (today >= booking.beginDate && (booking.endDate ? today <= booking.endDate : true)) {
        for (let i = 0; i < periods.length; i++)
            if (today >= periods[i].beginDate && (periods[i].endDate ? today <= periods[i].endDate : true))
                return true;
    } else if (periods.length) return true;

    return false;
}

export const CalendarCard = ({rooms, clients, triggerRefresh}) => {
    const today = useMemo(() => moment().startOf("day"), []);
    const locations = useMemo(() =>
        rooms.map(room => ({id: room.id, title: room.name, capacity: room.capacity})), [rooms]);
    const items = useMemo(() => {
        if (keys(clients).length === 0) return [];

        return rooms.map(room =>
            [...room.bookings.map(b => ({
                id: _ID++,
                group: b.locationId,
                bookingId: b.id,
                title: clients[b.clientId]?.title,
                start_time: b.beginDate,
                end_time: b.endDate ? b.endDate + day : maxTime,
                clientId: b.clientId,
                isBooking: true,
                isCrisis: findIsCrisis(clients[b.clientId], b, today),
            })), ...room.reservations.map(r => ({
                id: _ID++,
                group: r.locationId,
                reservationId: r.id,
                title: (r.firstName || r.lastName) ? `${r.firstName ? r.firstName : ""} ${r.lastName ? r.lastName : ""}` :
                    `${r.gender === "M" ? "Jongen" : r.gender === "F" ? "Meisje" : "Onbekend"} van ${r.age ? r.age : "?"} jaar`,
                firstName: r.firstName,
                lastName: r.lastName,
                gender: r.gender,
                age: r.age,
                start_time: r.beginDate,
                end_time: r.endDate + day,
                isBooking: false,
            }))]).flat(1);
    }, [clients, rooms, today]);
    const [modalProps, setModalProps] = useState(null);
    const choiceModalRef = useRef();
    const [choiceModal, setChoiceModal] = useState(null);
    const [loader, setLoader] = useState(false);

    useEffect(() => setChoiceModal(new Modal(choiceModalRef.current)), []);

    const handleItemSelect = useCallback((itemId, e, time) => {
        setModalProps({item: find(items, ["id", itemId]), time});
        choiceModal.show();
    }, [items, choiceModal]);
    const handleCanvasClick = useCallback((locationId, time, e) => {
        setModalProps({locationId, time});
        choiceModal.show();
    }, [choiceModal]);

    return (
        <>
            <div className="card big-shadow" style={{width: "90vw"}}>
                {(loader || keys(clients).length === 0) && <DefaultFullPageLoader/>}
                <div className="card-header border-0">
                    <h5 className="card-title text-dark" style={{fontWeight: 600}}>
                        Overzicht koppelingen
                    </h5>
                </div>
                <div className="card-body pt-0">
                    <Timeline groups={locations}
                              items={items}
                              sidebarWidth={190}
                              stackItems={true}
                              defaultTimeStart={moment()}
                              defaultTimeEnd={moment().add(2, "months")}
                              onTimeChange={limitTimeline}
                              groupRenderer={groupRenderer}
                              traditionalZoom={true}
                              timeSteps={{second: 0, minute: 0, hour: 0, day: 1, month: 1, year: 1}}
                              selected={[]}
                              itemRenderer={itemRenderer}
                              onItemSelect={handleItemSelect}
                              onCanvasClick={handleCanvasClick}
                    >
                        <TimelineHeaders className="sticky bg-info">
                            <SidebarHeader>
                                {({getRootProps}) =>
                                    <div {...getRootProps()}
                                         className="text-white d-flex align-items-center justify-content-center">
                                        Kamers
                                    </div>}
                            </SidebarHeader>
                            <DateHeader unit="primaryHeader"/>
                            <DateHeader/>
                        </TimelineHeaders>
                        <TimelineMarkers>
                            <TodayMarker/>
                        </TimelineMarkers>
                    </Timeline>
                </div>
            </div>
            <ChoiceModal modalRef={choiceModalRef} modal={choiceModal} clients={values(clients)}
                         triggerRefresh={triggerRefresh} setLoader={setLoader} {...modalProps}/>
        </>
    );
}