import {Field, useField} from "formik";
import DatePicker from "react-datepicker";
import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import "./DatepickerInput.scss";

export const DatepickerInput = ({label, required, ...props}) => {
    const [field, meta, helpers] = useField(props);
    const invalid = meta.touched && meta.error;

    return <>
        <label className="form-label fs-6" style={{color: "#3f4254"}} htmlFor={props.id || props.name}>
            <span className={required ? "required" : ""}>{label}</span>
        </label>
        <Field className={"form-control" + (invalid ? " is-invalid" : "")}
               {...props}
               component={DatePicker}
               dateFormat="dd-MM-yyyy"
               showWeekNumbers
               todayButton="Vandaag"
               selected={field.value}
               onChange={date => helpers.setValue(date)}
        />
        {invalid && <div className="invalid-feedback" style={{display: "block"}}>{meta.error}</div>}
    </>;
};