import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";
import {BrowserRouter} from "react-router-dom";
import {library} from "@fortawesome/fontawesome-svg-core";
import {PublicClientApplication} from "@azure/msal-browser";
import {AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate} from "@azure/msal-react";
import {msalConfig} from "api/auth/authConfig";
import {AlertProvider} from "contexts/AlertContext/AlertContext";
import moment from "moment-timezone";
import nlLocale from "moment/locale/nl";
import {Unauthenticated} from "containers/Body/Unauthenticated";
import {Authenticated} from "./containers/Body/Authenticated";

moment.tz.setDefault("Europe/Amsterdam");
moment.updateLocale("nl", nlLocale);
library.add();

const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(
    <React.StrictMode>
        <AlertProvider>
            <MsalProvider instance={msalInstance}>
                <BrowserRouter>
                    <AuthenticatedTemplate>
                        <Authenticated/>
                    </AuthenticatedTemplate>
                    <UnauthenticatedTemplate>
                        <Unauthenticated/>
                    </UnauthenticatedTemplate>
                </BrowserRouter>
            </MsalProvider>
        </AlertProvider>
    </React.StrictMode>
    ,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
