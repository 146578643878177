import React, {useCallback, useContext} from "react";
import {useMsal} from "@azure/msal-react";
import {AlertContext} from "contexts/AlertContext/AlertContext";
import {convertReservation, deleteReservation, postReservation, putReservation} from "api/rest";
import {ReservationForm} from "components/Form/ReservationForm";
import moment from "moment-timezone";
import {day, maxTime} from "containers/LocationOverview/CalendarCard";

export const ReservationModalContent = (
    {clients, closeModal, setLoader, triggerRefresh, type, item, locationId, time}) => {
    const msalContext = useMsal();
    const {handleErrorAlert} = useContext(AlertContext);

    const handleSubmit = useCallback(values => {
        if (type !== "edit" && type !== "new") return;

        setLoader(true);
        const body = {
            firstName: values.firstName !== "" ? values.firstName : null,
            lastName: values.lastName !== "" ? values.lastName : null,
            gender: values.gender,
            age: values.age,
            locationId: values.locationId,
            beginDate: values.beginDate.valueOf(),
            endDate: values.endDate.valueOf()
        };
        (type === "edit" ?
            putReservation(msalContext, item.reservationId, body)
                .catch(error => handleErrorAlert(error, "Kan reservering niet aanpassen")) :
            postReservation(msalContext, body).then(() => triggerRefresh())
                .catch(error => handleErrorAlert(error, "Kan reservering niet maken")))
            .then(() => triggerRefresh())
            .finally(() => setLoader(false));
        closeModal();
    }, [closeModal, handleErrorAlert, type, item, msalContext, setLoader, triggerRefresh]);
    const handleDelete = useCallback(() => {
        setLoader(true);
        deleteReservation(msalContext, item.reservationId)
            .then(() => triggerRefresh())
            .catch(error => handleErrorAlert(error, "Kan reservering niet verwijderen"))
            .finally(() => setLoader(false));
        closeModal();
    }, [closeModal, handleErrorAlert, item, msalContext, setLoader, triggerRefresh]);
    const handleConvert = useCallback(values => {
        setLoader(true);
        convertReservation(msalContext, item.reservationId, values.clientId)
            .then(() => triggerRefresh())
            .catch(error => handleErrorAlert(error, "Kan reservering niet omzetten"))
            .finally(() => setLoader(false));
        closeModal();
    }, [closeModal, handleErrorAlert, item, msalContext, setLoader, triggerRefresh]);

    return (
        <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title text-dark" style={{fontWeight: 600}}>
                    {type === "edit" ? "Aanpassen van reservering" :
                        type === "new" ? "Nieuwe reservering maken" : "Omzetten van reservering"}
                </h5>
                <button type="button" className="btn-close" onClick={closeModal} aria-label="Close"/>
            </div>
            <div className="modal-body">
                {type === "edit" ?
                    <ReservationForm clients={clients} locationId={item?.group || locationId} firstName={item.firstName}
                                     lastName={item.lastName} gender={item.gender} age={item.age}
                                     beginDate={moment(item.start_time).toDate()}
                                     endDate={item.end_time !== maxTime ? moment(item.end_time - day).toDate() : ""}
                                     handleSubmit={handleSubmit} handleDelete={handleDelete}/> :
                    type === "new" ?
                        <ReservationForm clients={clients} locationId={item?.group || locationId}
                                         beginDate={moment(time).startOf("day").toDate()}
                                         handleSubmit={handleSubmit}/> :
                        <ReservationForm clients={clients} firstName={item.firstName} lastName={item.lastName}
                                         gender={item.gender} age={item.age}
                                         beginDate={moment(item.start_time).toDate()}
                                         endDate={item.end_time !== maxTime ? moment(item.end_time - day).toDate() : ""}
                                         handleConvert={handleConvert}/>}
            </div>
        </div>
    );
}