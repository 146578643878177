import React, {useMemo} from "react";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import {DatepickerInput} from "components/Form/DatepickerInput";
import moment from "moment-timezone";
import {CustomSelectInput} from "components/Form/ClientSelectInput";

const validationSchema = Yup.object({
    clientId: Yup.number().integer().required("Kies een client"),
    beginDate: Yup.date().required("Vul een begindatum in"),
    endDate: Yup.date().required("Vul een einddatum in")
});

export const BookingForm = ({clients, handleSubmit, handleDelete, ...props}) => {
    const clientOptions = useMemo(() =>
        clients.map(client => ({value: client.id, label: client.title})), [clients]);

    return (
        <Formik
            initialValues={{
                clientId: props.clientId || "",
                locationId: props.locationId,
                beginDate: props.beginDate || "",
                endDate: props.endDate || ""
            }}
            validationSchema={validationSchema} onSubmit={handleSubmit}
        >
            {formik => <Form>
                <div className="mb-3">
                    <CustomSelectInput label="Client" name="clientId" options={clientOptions}
                                       isDisabled={!!handleDelete} required={true}/>
                </div>

                <div className="mb-3">
                    <DatepickerInput label="Begindatum" name="beginDate" required={true}
                                     maxDate={formik.values.endDate ? moment(formik.values.endDate).toDate() : ""}/>
                </div>
                <div className="mb-3">
                    <DatepickerInput label="Einddatum" name="endDate" required={true}
                                     minDate={formik.values.beginDate ? moment(formik.values.beginDate).toDate() : ""}/>
                </div>

                <div className="d-flex justify-content-between pt-3">
                    <button type="submit" className="btn btn-primary">{handleDelete ? "Pas aan" : "Koppel"}</button>
                    {handleDelete &&
                    <button type="button" className="btn btn-danger" onClick={handleDelete}>Verwijder</button>}
                </div>
            </Form>}
        </Formik>
    );
}