import React, {useMemo} from "react";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import {DatepickerInput} from "components/Form/DatepickerInput";
import moment from "moment-timezone";
import {CustomInput} from "components/Form/CustomInput";
import {SelectInput} from "components/Form/SelectInput";
import {CustomSelectInput} from "components/Form/ClientSelectInput";

const validationSchema = {
    firstName: Yup.string(),
    lastName: Yup.string(),
    gender: Yup.string().oneOf(["M", "F", "O"], "Onbekend geslacht").required("Is het een jongen, meisje of onbekend"),
    age: Yup.number().integer().required("Vul een leeftijd in"),
    beginDate: Yup.date().required("Vul een begindatum in"),
    endDate: Yup.date().required("Vul een einddatum in")
};

export const ReservationForm = ({clients, handleSubmit, handleDelete, handleConvert, ...props}) => {
    const clientOptions = useMemo(() =>
        clients.map(client => ({value: client.id, label: client.title})), [clients]);

    return (
        <Formik
            initialValues={{
                clientId: "",
                locationId: props.locationId,
                firstName: props.firstName || "",
                lastName: props.lastName || "",
                gender: props.gender || "M",
                age: props.age || ((props.age === 0) ? props.age : ""),
                beginDate: props.beginDate || "",
                endDate: props.endDate || ""
            }}
            validationSchema={Yup.object({
                ...validationSchema,
                clientId: handleConvert ? Yup.number().integer().required("Kies een client") : Yup.number().integer()
            })}
            onSubmit={handleConvert || handleSubmit}
        >
            {formik => <Form>
                {handleConvert &&
                <div className="mb-3">
                    <CustomSelectInput label="Client" name="clientId" options={clientOptions} required={true}/>
                </div>}

                <div className="mb-3">
                    <CustomInput label="Voornaam" name="firstName" type="text" disabled={!!handleConvert}/>
                </div>
                <div className="mb-3">
                    <CustomInput label="Achternaam" name="lastName" type="text" disabled={!!handleConvert}/>
                </div>

                <div className="mb-3">
                    <SelectInput label="Geslacht" name="gender" required={true} disabled={!!handleConvert}>
                        <option value="M">Jongen</option>
                        <option value="F">Meisje</option>
                        <option value="O">Onbekend</option>
                    </SelectInput>
                </div>

                <div className="mb-3">
                    <CustomInput label="Leeftijd" name="age" type="number" required={true} min={0}
                                 disabled={!!handleConvert}/>
                </div>

                <div className="mb-3">
                    <DatepickerInput label="Begindatum" name="beginDate" required={true} disabled={!!handleConvert}
                                     maxDate={formik.values.endDate ? moment(formik.values.endDate).toDate() : ""}/>
                </div>
                <div className="mb-3">
                    <DatepickerInput label="Einddatum" name="endDate" required={true} disabled={!!handleConvert}
                                     minDate={formik.values.beginDate ? moment(formik.values.beginDate).toDate() : ""}/>
                </div>

                <div className="d-flex justify-content-between pt-3">
                    <button type="submit" className="btn btn-primary">
                        {handleDelete ? "Pas aan" : handleConvert ? "Omzetten" : "Koppel"}
                    </button>
                    {handleDelete &&
                    <button type="button" className="btn btn-danger" onClick={handleDelete}>Verwijder</button>}
                </div>
            </Form>}
        </Formik>
    );
}