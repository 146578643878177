import React, {useCallback, useContext} from "react";
import {BookingForm} from "components/Form/BookingForm";
import moment from "moment-timezone";
import {day, maxTime} from "containers/LocationOverview/CalendarCard";
import {useMsal} from "@azure/msal-react";
import {AlertContext} from "contexts/AlertContext/AlertContext";
import {deleteBooking, postBooking, putBooking} from "api/rest";

export const BookingModalContent = (
    {closeModal, setLoader, triggerRefresh, isEdit, item, locationId, time, clients}) => {
    const msalContext = useMsal();
    const {handleErrorAlert} = useContext(AlertContext);

    const handleSubmit = useCallback(values => {
        setLoader(true);
        const body = {
            clientId: values.clientId,
            locationId: values.locationId,
            beginDate: values.beginDate.valueOf(),
            endDate: values.endDate.valueOf()
        };
        (isEdit ?
            putBooking(msalContext, item.bookingId, body)
                .catch(error => handleErrorAlert(error, "Kan koppeling niet aanpassen")) :
            postBooking(msalContext, body)
                .catch(error => handleErrorAlert(error, "Kan koppeling niet maken")))
            .then(() => triggerRefresh())
            .finally(() => setLoader(false));
        closeModal();
    }, [setLoader, isEdit, item, msalContext, closeModal, triggerRefresh, handleErrorAlert]);
    const handleDelete = useCallback(() => {
        setLoader(true);
        deleteBooking(msalContext, item.bookingId)
            .then(() => triggerRefresh())
            .catch(error => handleErrorAlert(error, "Kan koppeling niet verwijderen"))
            .finally(() => setLoader(false));
        closeModal();
    }, [closeModal, handleErrorAlert, item, msalContext, triggerRefresh, setLoader]);

    return (
        <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title text-dark" style={{fontWeight: 600}}>
                    {isEdit ? "Aanpassen van koppeling" : "Nieuwe koppeling maken"}
                </h5>
                <button type="button" className="btn-close" onClick={closeModal} aria-label="Close"/>
            </div>
            <div className="modal-body">
                {isEdit ?
                    <BookingForm clients={clients} locationId={item?.group || locationId} clientId={item.clientId}
                                 beginDate={moment(item.start_time).toDate()}
                                 endDate={item.end_time !== maxTime ? moment(item.end_time - day).toDate() : ""}
                                 handleSubmit={handleSubmit} handleDelete={handleDelete}/> :
                    <BookingForm clients={clients} locationId={item?.group || locationId}
                                 beginDate={moment(time).startOf("day").toDate()}
                                 handleSubmit={handleSubmit}/>}
            </div>
        </div>
    );
}