import {useEffect, useRef} from "react";

export function reduceWithAttr(toReduce, attr) {
    return toReduce.reduce((result, e) => {
        result[e[attr]] = e;
        return result;
    }, {});
}

export function useEffectSkipFirst(fn, arr) {
    const isFirst = useRef(true);

    useEffect(() => {
        if (isFirst.current) {
            isFirst.current = false;
            return;
        }

        return fn();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, arr);
}