import axios from "axios";
import {requestAccessToken} from "api/auth/auth";

const authRequest = axios.create({
    baseURL: process.env.REACT_APP_BOOKING_API_URI,
    timeout: 30000
});
authRequest.defaults.headers.post["Content-Type"] = "application/json";
authRequest.defaults.headers.put["Content-Type"] = "application/json";
authRequest.interceptors.request.use(
    (config) => configInterceptor(config),
    (error) => Promise.reject(error)
);

function configInterceptor(config) {
    const instance = config["instance"];
    const accounts = config["accounts"];

    return requestAccessToken(instance, accounts).then(response => {
        config.headers["Authorization"] = `Bearer ${response.accessToken}`;
        return config;
    });
}

export function downloadCSV(url) {
    const filename = url.split(".com/")[1].split(".csv")[0];
    axios({url, method: "GET", responseType: "blob"})
        .then(response => {
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(new Blob([response.data]));
            link.setAttribute("download", `${filename}.csv`);
            link.click();
        })
}

export function getRootLocations(msalContext) {
    return authRequest.get("/locations/root", {
        "instance": msalContext.instance, "accounts": msalContext.accounts
    });
}

export function getLocation(msalContext, locationId, embed) {
    const params = {};
    if (embed.length !== 0) params["embed"] = embed.join(",");

    return authRequest.get(`/locations/${locationId}`, {
        "instance": msalContext.instance, "accounts": msalContext.accounts,
        params
    });
}

export function getLocationsEmptyBetween(msalContext, beginDate, endDate) {
    const params = {beginDate, endDate};

    return authRequest.get("/locations/empty_between", {
        "instance": msalContext.instance, "accounts": msalContext.accounts,
        params
    });
}

export function getLocationNames(msalContext, body) {
    return authRequest.post("/locations/names", body, {
        "instance": msalContext.instance, "accounts": msalContext.accounts
    });
}

export function getClients(msalContext, page, embed) {
    const params = {page};
    if (embed.length !== 0) params["embed"] = embed.join(",");

    return authRequest.get("/clients", {
        "instance": msalContext.instance, "accounts": msalContext.accounts,
        params
    });
}

export function getClientsNotBooked(msalContext) {
    return authRequest.get("/clients/not_booked", {
        "instance": msalContext.instance, "accounts": msalContext.accounts
    });
}

export function getBookingsBeginningBetween(msalContext, beginDate, endDate) {
    const params = {beginDate, endDate};

    return authRequest.get("/bookings/beginning_between", {
        "instance": msalContext.instance, "accounts": msalContext.accounts,
        params
    });
}

export function getBookingsEndingBetween(msalContext, beginDate, endDate) {
    const params = {beginDate, endDate};

    return authRequest.get("/bookings/ending_between", {
        "instance": msalContext.instance, "accounts": msalContext.accounts,
        params
    });
}

export function postBooking(msalContext, body) {
    return authRequest.post("/bookings", body, {
        "instance": msalContext.instance, "accounts": msalContext.accounts
    });
}

export function putBooking(msalContext, bookingId, body) {
    return authRequest.put(`/bookings/${bookingId}`, body, {
        "instance": msalContext.instance, "accounts": msalContext.accounts
    });
}

export function deleteBooking(msalContext, bookingId) {
    return authRequest.delete(`/bookings/${bookingId}`, {
        "instance": msalContext.instance, "accounts": msalContext.accounts
    });
}

export function refreshONSData(msalContext) {
    return authRequest.get("/refresh_ons_data", {
        "instance": msalContext.instance, "accounts": msalContext.accounts
    });
}

export function postReservation(msalContext, body) {
    return authRequest.post("/reservations", body, {
        "instance": msalContext.instance, "accounts": msalContext.accounts
    });
}

export function putReservation(msalContext, reservationId, body) {
    return authRequest.put(`/reservations/${reservationId}`, body, {
        "instance": msalContext.instance, "accounts": msalContext.accounts
    });
}

export function deleteReservation(msalContext, reservationId) {
    return authRequest.delete(`/reservations/${reservationId}`, {
        "instance": msalContext.instance, "accounts": msalContext.accounts
    });
}

export function convertReservation(msalContext, reservationId, clientId) {
    const params = {clientId};

    return authRequest.get(`/reservations/${reservationId}/convert`, {
        "instance": msalContext.instance, "accounts": msalContext.accounts,
        params
    });
}

export function getOccupancyRateReport(msalContext) {
    return authRequest.get("/reports/occupancy_rate", {
        "instance": msalContext.instance, "accounts": msalContext.accounts
    });
}

export function getOccupancyReport(msalContext) {
    return authRequest.get("/reports/occupancy", {
        "instance": msalContext.instance, "accounts": msalContext.accounts
    });
}

export function getCrisisRateReport(msalContext) {
    return authRequest.get("/reports/crisis_rate", {
        "instance": msalContext.instance, "accounts": msalContext.accounts
    });
}

export function getVacancyReport(msalContext) {
    return authRequest.get("/reports/vacancy", {
        "instance": msalContext.instance, "accounts": msalContext.accounts
    });
}