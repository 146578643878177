import React from "react";
import {loginRequest} from "api/auth/authConfig";
import {useMsal} from "@azure/msal-react";

function handleLogin(instance) {
    instance.loginRedirect(loginRequest).catch(e => console.error(e));
}

export const Unauthenticated = () => {
    const {instance} = useMsal();

    return (
        <div className="d-flex align-items-center flex-column p-3">
            <div className="d-flex mb-4">
                <a href="https://www.d3.nl/">
                    <img alt="Logo" src="/Logo_D3_600x600.png" style={{height: "100px"}}/>
                </a>
                <h3 className="d-flex align-items-center">Dromen, Durven,
                    <span className="text-danger" style={{whiteSpace: "pre"}}> Doen</span>
                </h3>
            </div>
            <div className="rounded-3 p-5 mx-auto big-shadow">
                <div className="form">
                    <div className="text-center mb-5">
                        <h4 className="mb-2">Inloggen</h4>
                        <div className="fw-bold" style={{color: "#b5b5c3"}}>
                            Nieuw hier? Vraag een D3 account aan!
                        </div>
                    </div>
                    <div className="text-center">
                        <button className="btn btn-info w-100" onClick={() => handleLogin(instance)}>
                            <img alt="Logo" src="https://upload.wikimedia.org/wikipedia/commons/4/44/Microsoft_logo.svg"
                                 style={{height: "20px"}} className="me-2"/>
                            Ga door met Microsoft
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}