import React, {useContext, useEffect, useMemo, useState} from "react";
import {useMsal} from "@azure/msal-react";
import {AlertContext} from "contexts/AlertContext/AlertContext";
import {
    getBookingsBeginningBetween,
    getBookingsEndingBetween,
    getClientsNotBooked,
    getLocationsEmptyBetween,
    getRootLocations
} from "api/rest";
import {LocationsCard} from "components/Card/LocationsCard";
import {keys, sortBy, values} from "lodash";
import {ClientsTableCard} from "components/Card/ClientsTableCard";
import moment from "moment-timezone";
import {BookingsTableCard} from "components/Card/BookingsTableCard";
import {LocationsTableCard} from "components/Card/LocationsTableCard";
import {ClientsAlmostAdultTableCard} from "components/Card/ClientsAlmostAdultTableCard";

export const Dashboard = ({clients}) => {
    const msalContext = useMsal();
    const {handleErrorAlert} = useContext(AlertContext);

    const [rootLocations, setRootLocations] = useState([]);
    const [loaderRootLocations, setLoaderRootLocations] = useState(false);
    const [clientsWithoutRoom, setClientsWithoutRoom] = useState([]);
    const [loaderClientsWithoutRoom, setLoaderClientsWithoutRoom] = useState(false);
    const [upcomingBookings, setUpcomingBookings] = useState([]);
    const [loaderUpcomingBookings, setLoaderUpcomingBookings] = useState(false);
    const [endingBookings, setEndingBookings] = useState([]);
    const [loaderEndingBookings, setLoaderEndingBookings] = useState(false);
    const [emptyRooms, setEmptyRooms] = useState([]);
    const [loaderEmptyRooms, setLoaderEmptyRooms] = useState(false);

    const today = useMemo(() => moment().startOf("day"), []);
    const clientsAlmostAdult = useMemo(() => {
        return sortBy(values(clients).filter(c => c.isAlmostAdult), "firstName");
    }, [clients]);

    useEffect(() => {
        setLoaderRootLocations(true);
        setLoaderClientsWithoutRoom(true);
        setLoaderUpcomingBookings(true);
        setLoaderEndingBookings(true);
        setLoaderEmptyRooms(true);

        getRootLocations(msalContext)
            .then(response => {
                setRootLocations(response.data);
                getLocationsEmptyBetween(msalContext,
                    moment().startOf("day").subtract(1, "weeks").valueOf(),
                    moment().startOf("day").add(1, "weeks").valueOf())
                    .then(response => setEmptyRooms(sortBy(response.data, "materializedPath")))
                    .catch(error => handleErrorAlert(error, "Kan leegstaande kamers niet ophalen"))
                    .finally(() => setLoaderEmptyRooms(false));
            })
            .catch(error => handleErrorAlert(error, "Kan hoofdlocaties niet ophalen"))
            .finally(() => setLoaderRootLocations(false));
        getClientsNotBooked(msalContext)
            .then(response => setClientsWithoutRoom(sortBy(response.data, "firstName")))
            .catch(error => handleErrorAlert(error, "Kan clienten zonder koppeling niet ophalen"))
            .finally(() => setLoaderClientsWithoutRoom(false));
        getBookingsBeginningBetween(msalContext,
            today.valueOf(),
            moment().startOf("day").add(1, "weeks").valueOf())
            .then(response => {
                setUpcomingBookings(sortBy(response.data, "beginDate"));
                getBookingsEndingBetween(msalContext,
                    today.valueOf(),
                    moment().startOf("day").add(1, "weeks").valueOf())
                    .then(response => setEndingBookings(sortBy(response.data, "beginDate")))
                    .catch(error => handleErrorAlert(error, "Kan aflopende koppelingen niet ophalen"))
                    .finally(() => setLoaderEndingBookings(false));
            })
            .catch(error => handleErrorAlert(error, "Kan aankomende koppelingen niet ophalen"))
            .finally(() => setLoaderUpcomingBookings(false));
    }, [today, msalContext, handleErrorAlert]);

    return (
        <div className="row">
            <div className="col-12 col-lg-4 mb-3">
                <LocationsCard locations={rootLocations} title="Overzicht hoofdlocaties" loader={loaderRootLocations}
                               rowCols="row-cols-1"/>
            </div>
            <div className="col-12 col-lg-8 mb-3">
                <div className="row">
                    <div className="col-12 mb-3">
                        <BookingsTableCard bookings={upcomingBookings} clients={clients} title="Koppelingen"
                                           subTitle="Starten binnen 1 week" dateAttribute="beginDate"
                                           loader={loaderUpcomingBookings || keys(clients).length === 0}/>
                    </div>
                    <div className="col-12">
                        <BookingsTableCard bookings={endingBookings} clients={clients} title="Koppelingen"
                                           subTitle="Lopen af binnen 1 week" dateAttribute="endDate"
                                           loader={loaderEndingBookings || keys(clients).length === 0}/>
                    </div>
                </div>
            </div>
            <div className="col-12 col-lg-4 mb-3" style={{maxHeight: "56vh"}}>
                <LocationsTableCard locations={emptyRooms} title="Kamers" subTitle="Minstens 1 week leeg"
                                    loader={loaderEmptyRooms}/>
            </div>
            <div className="col-12 col-lg-4 mb-3" style={{maxHeight: "56vh"}}>
                <ClientsTableCard clients={clientsWithoutRoom} title="Clienten" subTitle="Zonder koppeling"
                                  loader={loaderClientsWithoutRoom}/>
            </div>
            <div className="col-12 col-lg-4 mb-3" style={{maxHeight: "56vh"}}>
                <ClientsAlmostAdultTableCard clients={clientsAlmostAdult} title="Clienten"
                                             subTitle="Binnen 6 maanden 18 jaar" loader={keys(clients).length === 0}/>
            </div>
        </div>
    );
}