import React, {useCallback} from "react";
import {DefaultFullPageLoader} from "components/FullPageLoader/DefaultFullPageLoader";
import moment from "moment-timezone";

export const BookingsTableCard = ({bookings, clients, title, subTitle, dateAttribute, loader}) => {
    const createBookingRow = useCallback((booking) => {
        const client = !booking.isReservation ? clients[booking.clientId] :
            {firstName: booking.firstName, lastName: booking.lastName};

        return <tr key={booking.id} className={booking.isReservation ? "table-warning" : ""}>
            <th>{client?.clientNumber}</th>
            <td>{`${client?.firstName || ""} ${client?.lastName || ""}`}</td>
            <td>{booking.location.parentName}</td>
            <td>{booking.location.name}</td>
            <td>{moment(booking[dateAttribute]).format("dddd D MMMM")}</td>
        </tr>;
    }, [clients, dateAttribute]);

    return (
        <div className="card big-shadow h-100">
            {loader && <DefaultFullPageLoader/>}
            <div className="card-header border-0 pb-0">
                <h5 className="card-title d-flex align-items-center">
                    <span className="text-dark" style={{fontWeight: 600}}>{title}</span>
                    <span className="text-muted ms-2" style={{fontSize: ".8rem"}}>{subTitle}</span>
                </h5>
            </div>
            <div className="card-body pt-0" style={{overflowY: "scroll"}}>
                <table className="table table-hover table-sm table-responsive">
                    {bookings.length === 0 &&
                    <caption className="text-center">Geen koppelingen gevonden</caption>}
                    <thead>
                    <tr className="text-muted" style={{fontWeight: 600}}>
                        <th>Client nr.</th>
                        <th>Naam</th>
                        <th>Locatie</th>
                        <th>Kamer</th>
                        <th>Datum</th>
                    </tr>
                    </thead>
                    <tbody>
                    {!loader && bookings.map(booking => createBookingRow(booking))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}