import React from "react";
import {DefaultFullPageLoader} from "components/FullPageLoader/DefaultFullPageLoader";
import {SubLocationButton} from "components/Button/SubLocationButton";

export const LocationsCard = ({locations, title, loader, rowCols}) => {
    return (
        <div className="card big-shadow h-100">
            {loader && <DefaultFullPageLoader/>}
            <div className="card-header border-0">
                <h5 className="card-title text-dark" style={{fontWeight: 600}}>{title}</h5>
            </div>
            <div className="card-body pt-0">
                <div className={"row justify-content-center " +
                (rowCols ? rowCols : "row-cols-1 row-cols-md-2 row-cols-lg-3")}>
                    {locations.filter(l => l.statistics.totalRooms)
                        .map(l => <SubLocationButton key={l.id} subLocation={l}/>)}
                </div>
            </div>
        </div>
    );
}