import React, {useCallback} from "react";
import {DefaultFullPageLoader} from "components/FullPageLoader/DefaultFullPageLoader";
import moment from "moment-timezone";

export const ClientsAlmostAdultTableCard = ({clients, title, subTitle, loader}) => {
    const createClientRow = useCallback((client) =>
        <tr key={client.id}>
            <th>{client.clientNumber}</th>
            <td>{`${client.firstName} ${client.lastName}`}</td>
            <td>{moment(client.dateOfBirth).format("DD-MM-YYYY")}</td>
        </tr>, []);

    return (
        <div className="card big-shadow h-100">
            {loader && <DefaultFullPageLoader/>}
            <div className="card-header border-0 pb-0">
                <h5 className="card-title d-flex align-items-center">
                    <span className="text-dark" style={{fontWeight: 600}}>{title}</span>
                    <span className="text-muted ms-2" style={{fontSize: ".8rem"}}>{subTitle}</span>
                </h5>
            </div>
            <div className="card-body pt-0" style={{overflowY: "scroll"}}>
                <table className="table table-hover table-sm table-responsive">
                    {clients.length === 0 &&
                    <caption className="text-center">Geen clienten bijna 18</caption>}
                    <thead>
                    <tr className="text-muted" style={{fontWeight: 600}}>
                        <th>Client nr.</th>
                        <th>Naam</th>
                        <th>Geboortedatum</th>
                    </tr>
                    </thead>
                    <tbody>
                    {clients.map(client => createClientRow(client))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}