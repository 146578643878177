import {useField} from "formik";
import React from "react";

export const SelectInput = ({label, required, ...props}) => {
    const [field, meta] = useField(props);
    const invalid = meta.touched && meta.error;

    return <>
        <label className="form-label fs-6" style={{color: "#3f4254"}} htmlFor={props.id || props.name}>
            <span className={required ? "required" : ""}>{label}</span>
        </label>
        <select className={"form-select form-control" + (invalid ? " is-invalid" : "")}  {...field} {...props}/>
        {invalid && <div className="invalid-feedback">{meta.error}</div>}
    </>;
};