import React, {useEffect, useState} from "react";
import {keys, max, min} from "lodash";
import {DefaultFullPageLoader} from "components/FullPageLoader/DefaultFullPageLoader";
import moment from "moment-timezone";
import {Chart} from "react-google-charts";

export const LocationSummaryCard = ({clients, rooms}) => {
    const [nGenderData, setNGenderData] = useState([["Geslacht", "Aantal"]]);
    const [minAge, setMinAge] = useState(null);
    const [maxAge, setMaxAge] = useState(null);

    useEffect(() => {
        if (keys(clients).length === 0) return;

        const today = moment();
        let nMale = 0, nFemale = 0, nUnknown = 0;
        const ages = [];

        rooms.forEach(room => room.bookings
            .filter(booking => today >= booking.beginDate && (booking.endDate === null || today <= booking.endDate))
            .forEach(booking => {
                const client = clients[booking.clientId];

                if (client?.dateOfBirth !== null) ages.push(today.diff(moment(client?.dateOfBirth), "years"));
                if (client?.gender === "M") nMale++;
                else if (client?.gender === "F") nFemale++;
                else nUnknown++;
            })
        );

        setNGenderData([
            ["Geslacht", "Aantal"],
            ["Jongens", nMale],
            ["Meisjes", nFemale],
            ["Onbekend", nUnknown]
        ]);
        setMinAge(min(ages));
        setMaxAge(max(ages));
    }, [clients, rooms]);

    return (
        <div className="card big-shadow h-100">
            {keys(clients).length === 0 && <DefaultFullPageLoader/>}
            <div className="card-header border-0">
                <h5 className="card-title text-dark" style={{fontWeight: 600}}>
                    Samenvatting
                </h5>
            </div>
            <div className="card-body py-0">
                <div className="d-flex justify-content-center">
                    <Chart width="20vw" height="20vh" chartType="PieChart" data={nGenderData}
                           options={{
                               pieStartAngle: 180, pieSliceText: "value", pieSliceTextStyle: {fontSize: 18},
                               toolTip: {ignoreBounds: true}, legend: {position: "top", alignment: "center"},
                               backgroundColor: "transparent"
                           }}/>
                </div>
                {!!minAge && !!maxAge &&
                <div className="d-flex justify-content-around">
                    <div className="d-flex flex-column align-items-center">
                        <div className="text-muted mb-1" style={{fontWeight: 500}}>Jongste</div>
                        <div className="fs-1" style={{fontWeight: 600}}>{minAge}</div>
                    </div>
                    <div className="d-flex flex-column align-items-center">
                        <div className="text-muted mb-1" style={{fontWeight: 500}}>Oudste</div>
                        <div className="fs-1" style={{fontWeight: 600}}>{maxAge}</div>
                    </div>
                </div>}
            </div>
        </div>
    );
}