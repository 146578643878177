import {Field, useField} from "formik";
import Select from "react-select";
import React from "react";

export const CustomSelectInput = ({label, options, required, ...props}) => {
    const [field, meta, helpers] = useField(props);
    const invalid = meta.touched && meta.error;

    return <>
        <label className="form-label fs-6" style={{color: "#3f4254"}} htmlFor={props.id || props.name}>
            <span className={required ? "required" : ""}>{label}</span>
        </label>
        <Field className={invalid ? "is-invalid" : ""}
               {...props}
               component={Select}
               options={options}
               placeholder="Zoek client..."
               defaultValue={options.filter(option => option.value === field.value)}
               onChange={({value}) => helpers.setValue(value)}
               filterOption={(candidate, input) =>
                   input.length > 0 && candidate.label.toLowerCase().includes(input?.toLowerCase())}
               noOptionsMessage={({inputValue}) => inputValue.length > 0 ?
                   "Geen clienten gevonden" : "Typ minimaal 1 letter..."}
        />
        {invalid && <div className="invalid-feedback">{meta.error}</div>}
    </>;
};