import React from "react";
import {AlertContext} from "contexts/AlertContext/AlertContext";

export const Alerts = () => {
    return (
        <AlertContext.Consumer>
            {({alerts, deleteAlert}) => alerts.map(alert =>
                <div key={alert.id} className={`alert ${alert.alertClass} alert-dismissible fade show mt-3 mb-0`}
                     role="alert">
                    <h5 className="alert-heading">{alert.alertHeader}</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"
                            onClick={() => deleteAlert(alert.id)}/>
                    <p className="mb-0" style={{whiteSpace: "pre-wrap"}}>{alert.alertText}</p>
                </div>)}
        </AlertContext.Consumer>
    );
}