import React, {useContext, useEffect, useState} from "react";
import {Sidebar} from "containers/Nav/Sidebar";
import {DefaultFullPageLoader} from "components/FullPageLoader/DefaultFullPageLoader";
import {Alerts} from "containers/Alerts/Alerts";
import {Route, Switch} from "react-router-dom";
import {useMsal} from "@azure/msal-react";
import {AlertContext} from "contexts/AlertContext/AlertContext";
import {getClients} from "api/rest";
import {reduceWithAttr} from "utils";
import {Dashboard} from "containers/Dashboard/Dashboard";
import {LocationOverview} from "containers/LocationOverview/LocationOverview";
import {sortBy} from "lodash";

function getAllClients(msalContext, page, allClients) {
    return getClients(msalContext, page, ["careOrderProducts"])
        .then(response => {
            if (response.data.length === 0) return allClients;

            allClients.push(...response.data.map(c => ({
                ...c, title: `${c.firstName ? c.firstName : ""} ${c.lastName ? c.lastName : ""} (${c.clientNumber})`,
                careOrderProducts: sortBy(c.careOrderProducts, "beginDate")
            })));

            return getAllClients(msalContext, page + 1, allClients);
        });
}

export const Authenticated = () => {
    const msalContext = useMsal();
    const {handleErrorAlert} = useContext(AlertContext);

    const [clients, setClients] = useState({});
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        getAllClients(msalContext, 1, [])
            .then(allClients => setClients(reduceWithAttr(allClients, "id")))
            .catch(error => handleErrorAlert(error, "Kan clienten niet ophalen"));
    }, [msalContext, handleErrorAlert]);

    return (
        <div className="d-flex flex-row">
            <Sidebar setLoader={setLoader}/>
            <div className="d-flex flex-column w-100" style={{backgroundColor: "#f5f8fa"}}>
                <div className="container-fluid">
                    <div className="row mb-3">
                        <div className="col">
                            <Alerts/>
                        </div>
                    </div>
                    <Switch>
                        <Route path="/:locationId">
                            <LocationOverview clients={clients}/>
                        </Route>
                        <Route path="/">
                            <Dashboard clients={clients}/>
                        </Route>
                    </Switch>
                </div>
            </div>
            {loader && <DefaultFullPageLoader/>}
        </div>
    );
}