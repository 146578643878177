import React, {useCallback, useState} from "react";
import {BookingModalContent} from "components/Modal/BookingModalContent";
import {ReservationModalContent} from "components/Modal/ReservationModalContent";

export const ChoiceModal = ({modalRef, modal, ...props}) => {
    const [choice, setChoice] = useState(null);

    const closeModal = useCallback(() => {
        modal.hide();
        setChoice(null);
    }, [modal]);

    return (
        <div className="modal" ref={modalRef} data-bs-backdrop="static" data-bs-keyboard="false"
             tabIndex="-1" aria-hidden="true">
            <div className="modal-dialog">
                {choice === "editBooking" && <BookingModalContent {...props} closeModal={closeModal} isEdit={true}/>}
                {choice === "newBooking" && <BookingModalContent {...props} closeModal={closeModal} isEdit={false}/>}
                {choice === "editReservation" &&
                <ReservationModalContent {...props} closeModal={closeModal} type="edit"/>}
                {choice === "newReservation" &&
                <ReservationModalContent {...props} closeModal={closeModal} type="new"/>}
                {choice === "convertReservation" &&
                <ReservationModalContent {...props} closeModal={closeModal} type="convert"/>}
                {choice === null &&
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title text-dark" style={{fontWeight: 600}}>Maak een keuze</h5>
                        <button type="button" className="btn-close" aria-label="Close" onClick={closeModal}/>
                    </div>
                    <div className="modal-body">
                        <div className="row justify-content-center row-cols-1 row-cols-lg-2">
                            <CardButton title="Koppeling" subTitle="Maken"
                                        handleClick={() => setChoice("newBooking")}/>
                            <CardButton title="Koppeling" subTitle="Aanpassen"
                                        handleClick={() => setChoice("editBooking")}
                                        isDisabled={!props.hasOwnProperty("item") || !props.item.isBooking}/>
                            <CardButton title="Reservering" subTitle="Maken"
                                        handleClick={() => setChoice("newReservation")}/>
                            <CardButton title="Reservering" subTitle="Aanpassen"
                                        handleClick={() => setChoice("editReservation")}
                                        isDisabled={!props.hasOwnProperty("item") || props.item.isBooking}/>
                            <CardButton title="Reservering" subTitle="Omzetten"
                                        handleClick={() => setChoice("convertReservation")}
                                        isDisabled={!props.hasOwnProperty("item") || props.item.isBooking}/>
                        </div>
                    </div>
                </div>}
            </div>
        </div>
    );
}

const CardButton = ({title, subTitle, handleClick, isDisabled}) => {
    return (
        <div className="col mb-3">
            <div className="card border-0">
                <div className="card-body p-0">
                    <button className="btn btn-outline-info btn-block py-3 w-100" onClick={handleClick}
                            disabled={isDisabled}>
                        <span className="d-flex flex-column">
                            <span className="fs-5" style={{fontWeight: 600}}>{title}</span>
                            <span className="text-muted" style={{fontWeight: 500}}>{subTitle}</span>
                        </span>
                    </button>
                </div>
            </div>
        </div>
    );
}