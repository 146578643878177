import React, {useContext} from "react";
import {Link, NavLink} from "react-router-dom";
import "./Sidebar.scss";
import {SignOutButton} from "components/Button/SignOutButton";
import {useMsal} from "@azure/msal-react";
import {AlertContext} from "contexts/AlertContext/AlertContext";
import {
    downloadCSV,
    getCrisisRateReport,
    getOccupancyRateReport,
    getOccupancyReport,
    getVacancyReport,
    refreshONSData
} from "api/rest";

function handleRefresh(msalContext, addAlert, handleErrorAlert, setLoader) {
    setLoader(true);
    refreshONSData(msalContext)
        .then(({data}) => {
            addAlert("alert-success", "Klaar met verversen", "Herlaad de pagina om de veranderingen te zien");

            if (data.clientsAdded) addAlert("alert-success", "", `${data.clientsAdded} clienten toegevoegd`);
            if (data.clientsUpdated) addAlert("alert-success", "", `${data.clientsUpdated} clienten bijgewerkt`);
            if (data.clientsDeleted) addAlert("alert-success", "", `${data.clientsDeleted} clienten verwijderd`);
            if (data.locationsAdded) addAlert("alert-success", "", `${data.locationsAdded} locaties toegevoegd`);
            if (data.locationsUpdated) addAlert("alert-success", "", `${data.locationsUpdated} locaties bijgewerkt`);
            if (data.locationsDeleted) addAlert("alert-success", "", `${data.locationsDeleted} locaties verwijderd`);
            if (data.bookingsAdded) addAlert("alert-success", "", `${data.bookingsAdded} koppelingen toegevoegd`);
            if (data.bookingsUpdated) addAlert("alert-success", "", `${data.bookingsUpdated} koppelingen bijgewerkt`);
            if (data.bookingsDeleted) addAlert("alert-success", "", `${data.bookingsDeleted} koppelingen verwijderd`);
            if (data.productsAdded) addAlert("alert-success", "", `${data.productsAdded} producten toegevoegd`);
            if (data.productsUpdated) addAlert("alert-success", "", `${data.productsUpdated} producten bijgewerkt`);
            if (data.productsDeleted) addAlert("alert-success", "", `${data.productsDeleted} producten verwijderd`);
            if (data.careOrdersAdded) addAlert("alert-success", "", `${data.careOrdersAdded} zorg legitimaties toegevoegd`);
            if (data.careOrdersUpdated) addAlert("alert-success", "", `${data.careOrdersUpdated} zorg legitimaties bijgewerkt`);
            if (data.careOrdersDeleted) addAlert("alert-success", "", `${data.careOrdersDeleted} zorg legitimaties verwijderd`);
            if (data.careOrderProductsAdded) addAlert("alert-success", "", `${data.careOrderProductsAdded} zorg legitimatie producten toegevoegd`);
            if (data.careOrderProductsUpdated) addAlert("alert-success", "", `${data.careOrderProductsUpdated} zorg legitimatie producten bijgewerkt`);
            if (data.careOrderProductsDeleted) addAlert("alert-success", "", `${data.careOrderProductsDeleted} zorg legitimatie producten verwijderd`);
        })
        .catch(error => handleErrorAlert(error))
        .finally(() => setLoader(false));
}

function handleOccupancyRateReport(msalContext, handleErrorAlert, setLoader) {
    setLoader(true);
    getOccupancyRateReport(msalContext)
        .then(response => downloadCSV(response.data.downloadUrl))
        .catch(error => handleErrorAlert(error))
        .finally(() => setLoader(false));
}

function handleOccupancyReport(msalContext, handleErrorAlert, setLoader) {
    setLoader(true);
    getOccupancyReport(msalContext)
        .then(response => downloadCSV(response.data.downloadUrl))
        .catch(error => handleErrorAlert(error))
        .finally(() => setLoader(false));
}

function handleCrisisRateReport(msalContext, handleErrorAlert, setLoader) {
    setLoader(true);
    getCrisisRateReport(msalContext)
        .then(response => downloadCSV(response.data.downloadUrl))
        .catch(error => handleErrorAlert(error))
        .finally(() => setLoader(false));
}

function handleVacancyRateReport(msalContext, handleErrorAlert, setLoader) {
    setLoader(true);
    getVacancyReport(msalContext)
        .then(response => downloadCSV(response.data.downloadUrl))
        .catch(error => handleErrorAlert(error))
        .finally(() => setLoader(false));
}

export const Sidebar = ({setLoader}) => {
    const msalContext = useMsal();
    const {addAlert, handleErrorAlert} = useContext(AlertContext);

    return (
        <div className="d-flex flex-column min-vh-100" style={{backgroundColor: "#1e1e2d"}}>
            <div className="d-flex align-items-center mt-3" style={{padding: "0 25px", backgroundColor: "#1b1b28"}}>
                <a href="https://www.d3.nl/" className="me-2">
                    <img alt="Logo" src="/Logo_D3_600x600.png" style={{height: "60px"}}/>
                </a>
                <span className="fs-5 fw-bold text-white">D3</span>
            </div>
            <div className="my-3">
                <div className="d-flex flex-column w-100">
                    <MenuHeader headerText="Navigatie"/>
                    <div className="menu-item">
                        <NavLink className="nav-link" to="/">Overzicht</NavLink>
                    </div>
                    <MenuHeader headerText="Speciaal"/>
                    <MenuLink linkText="Synchroniseer" tooltipText="Synchroniseer data van ONS naar het boekingssysteem"
                              handleClick={() => handleRefresh(msalContext, addAlert, handleErrorAlert, setLoader)}/>
                    <MenuHeader headerText="Rapportage"/>
                    <MenuLink linkText="Bezetting (%)" tooltipText="Op een bepaalde datum:
                    aantal koppelingen / aantal actieve kamers"
                              handleClick={() => handleOccupancyRateReport(msalContext, handleErrorAlert, setLoader)}/>
                    <MenuLink linkText="Bezetting (0,1,n)" tooltipText="Op een bepaalde datum: aantal koppelingen"
                              handleClick={() => handleOccupancyReport(msalContext, handleErrorAlert, setLoader)}/>
                    <MenuLink linkText="Crisis (%)" tooltipText="Op een bepaalde datum:
                    aantal crisis zorg legitimaties / aantal clienten die nooit op een AK locatie zijn geweest"
                              handleClick={() => handleCrisisRateReport(msalContext, handleErrorAlert, setLoader)}/>
                    <MenuLink linkText="Leegstand" tooltipText="Gemiddeld aantal dagen leeg"
                              handleClick={() => handleVacancyRateReport(msalContext, handleErrorAlert, setLoader)}/>
                    <div className="menu-item">
                        <div className="menu-content">
                            <div className="separator mx-2 my-3"/>
                        </div>
                    </div>
                    <div className="menu-item">
                        <SignOutButton/>
                    </div>
                </div>
            </div>
        </div>
    );
}

const MenuHeader = ({headerText}) => {
    return (
        <div className="menu-item">
            <div className="pt-4 pb-2">
                <div className="menu-section text-uppercase">
                    {headerText}
                </div>
            </div>
        </div>
    );
}

const MenuLink = ({linkText, link, handleClick, tooltipText}) => {
    return (
        <div className="menu-item" data-bs-toggle="tooltip" title={tooltipText}>
            <Link className="nav-link" to={link || "#"} onClick={handleClick}>
                {linkText}
            </Link>
        </div>
    );
}