import React from "react";
import {NavLink} from "react-router-dom";
import "./MaterializedPathCard.scss";

export const MaterializedPathCard = ({path, names}) => {
    return (
        <div className="card big-shadow">
            <div className="card-body d-flex align-items-center flex-wrap">
                <h5 className="m-0 text-dark" style={{fontWeight: 600}}>{names[path[path.length - 1]]}</h5>
                <span className="separator-vertical mx-3"/>
                <ol className="breadcrumb">
                    {path.length > 1 && path.slice(0, path.length - 1).map((p, i) => [
                        <li key={i} className="breadcrumb-item">
                            <NavLink className="text-muted text-hover-info" to={`/${p}`}>{names[p]}</NavLink>
                        </li>,
                        <li key={`${i}${p}`} className="breadcrumb-item">
                            <span className="bullet" style={{height: "2px", width: "5px"}}/>
                        </li>
                    ]).flat(1)}
                    <li className="breadcrumb-item text-dark">{names[path[path.length - 1]]}</li>
                </ol>
            </div>
        </div>
    );
}