import React, {useContext, useEffect, useMemo, useState} from "react";
import {useMsal} from "@azure/msal-react";
import {AlertContext} from "contexts/AlertContext/AlertContext";
import {useParams} from "react-router-dom";
import {getLocation} from "api/rest";
import {MaterializedPathCard} from "components/Card/MaterializedPathCard";
import {LocationsCard} from "components/Card/LocationsCard";
import {DefaultFullPageLoader} from "components/FullPageLoader/DefaultFullPageLoader";
import {CalendarCard} from "./CalendarCard";
import {LocationSummaryCard} from "components/Card/LocationSummaryCard";
import {MissingCareOrdersTableCard} from "./MissingCareOrdersTableCard";
import moment from "moment-timezone";

export const LocationOverview = ({clients}) => {
    const msalContext = useMsal();
    const {locationId} = useParams();
    const {handleErrorAlert} = useContext(AlertContext);

    const [location, setLocation] = useState({});
    const [loaderLocation, setLoaderLocation] = useState(false);
    const [trigger, setTrigger] = useState(false);
    const today = useMemo(() => moment().startOf("day"), []);

    useEffect(() => {
        setLoaderLocation(true);
        getLocation(msalContext, locationId, ["bookings", "reservations"])
            .then(response => setLocation(response.data))
            .catch(error => handleErrorAlert(error, "Kan locatie gegevens niet ophalen"))
            .finally(() => setLoaderLocation(false))
    }, [today, msalContext, handleErrorAlert, locationId, trigger]);

    return (
        <div className="row">
            {loaderLocation && <DefaultFullPageLoader/>}
            {location.location &&
            <div className="col-12 mb-3">
                <MaterializedPathCard
                    path={location.location ? location.location.materializedPath.split(".").slice(1) : []}
                    names={location.names}/>
            </div>}
            {location.subLocations?.length > 0 &&
            <div className="col-12 mb-3">
                <LocationsCard locations={location.subLocations} title="Overzicht sublocaties" loader={false}/>
            </div>}
            {location.rooms?.length > 0 && <>
                <div className="col-12 col-lg-2 mb-3">
                    <LocationSummaryCard clients={clients} rooms={location.rooms}/>
                </div>
                <div className="col-12 col-lg-10 mb-3">
                    <MissingCareOrdersTableCard clients={clients} rooms={location.rooms}/>
                </div>
                <div className="col-12 mb-3">
                    <CalendarCard rooms={location.rooms} clients={clients} triggerRefresh={() => setTrigger(t => !t)}/>
                </div>
            </>}
        </div>
    );
}