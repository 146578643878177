import React, {useCallback, useMemo} from "react";
import {find, keys, sortBy, uniq, values} from "lodash";
import {DefaultFullPageLoader} from "components/FullPageLoader/DefaultFullPageLoader";
import moment from "moment-timezone";
import {max} from "moment/moment";
import {reduceWithAttr} from "utils";

function mergeOverlappingPeriods(periods) {
    const merged = [];
    for (let i = 0; i < periods.length; i++) {
        if (merged.length === 0 ||
            moment(merged[merged.length - 1].endDate).add(1, "days").isBefore(periods[i].beginDate)) {
            merged.push({beginDate: periods[i].beginDate, endDate: periods[i].endDate});
        } else if (periods[i].endDate === null) {
            merged[merged.length - 1].endDate = null;
            break;
        } else merged[merged.length - 1].endDate = max(merged[merged.length - 1].endDate, periods[i].endDate);
    }

    return merged;
}

function findNonOverlappingPeriods(periods) {
    if (periods.length < 1) return [];

    const nonOverlapping = [];
    for (let i = 1; i < periods.length; i++) {
        if (periods[i - 1].endDate.isBefore(periods[i].beginDate))
            nonOverlapping.push({
                beginDate: moment(periods[i - 1].endDate).add(1, "days"),
                endDate: moment(periods[i].beginDate).subtract(1, "days")
            })
    }

    return nonOverlapping;
}

function findOverlappingPeriod(period, otherPeriod) {
    if (period.endDate?.isBefore(otherPeriod.beginDate) || period.beginDate.isAfter(otherPeriod.endDate)) return null;

    return {
        beginDate: period.beginDate.isBefore(otherPeriod.beginDate) ? otherPeriod.beginDate : period.beginDate,
        endDate: period.endDate?.isBefore(otherPeriod.endDate) ? period.endDate : otherPeriod.endDate
    };
}

function findHolesInPeriod(period, periods) {
    if (periods.length === 0) return [{beginDate: period.beginDate, endDate: period.endDate}];

    const mergedPeriods = mergeOverlappingPeriods(periods);
    const first = mergedPeriods[0];
    const last = mergedPeriods[mergedPeriods.length - 1];
    if (period.endDate?.isBefore(first.beginDate) || last.endDate?.isBefore(period.beginDate))
        return [{beginDate: period.beginDate, endDate: period.endDate}];

    const nonOverlappingPeriods = findNonOverlappingPeriods(mergedPeriods);
    const holes = nonOverlappingPeriods.map(p => findOverlappingPeriod(period, p)).filter(h => h !== null);

    if (period.beginDate.isBefore(first.beginDate))
        holes.push({beginDate: period.beginDate, endDate: moment(first.beginDate).subtract(1, "days")});
    if (period.endDate === null && last.endDate !== null)
        holes.push({beginDate: moment(last.endDate).add(1, "days"), endDate: null});
    else if (last.endDate !== null && period.endDate?.isAfter(last.endDate))
        holes.push({beginDate: moment(last.endDate).add(1, "days"), endDate: period.endDate});

    return mergeOverlappingPeriods(sortBy(holes, "beginDate"));
}

export const MissingCareOrdersTableCard = ({clients, rooms}) => {
    const today = useMemo(() => moment().startOf("day"), []);
    const bookings = useMemo(() => reduceWithAttr(
        rooms.map(r => r.bookings).flat(1)
            .map(b => ({...b, beginDate: moment(b.beginDate), endDate: b.endDate ? moment(b.endDate) : null}))
            .filter(b => b.endDate === null || b.endDate >= today),
        "id"), [rooms, today]);
    const careOrderProducts = useMemo(() => keys(clients).length === 0 ? {} :
        uniq(values(bookings).map(b => b.clientId)).reduce((result, clientId) => {
            result[clientId] = clients[clientId]?.careOrderProducts?.map(cOP => ({
                ...cOP, beginDate: moment(cOP.beginDate), endDate: cOP.endDate ? moment(cOP.endDate) : null
            }));
            return result;
        }, {}), [clients, bookings]);
    const holes = useMemo(() =>
        values(bookings).reduce((result, b) => {
            const tempHoles = findHolesInPeriod(b, careOrderProducts[b.clientId] || []);
            if (tempHoles.length) result[b.id] = tempHoles;
            return result;
        }, {}), [bookings, careOrderProducts]);

    const createHoleRows = useCallback((bookingId, i) => {
        const rowSpan = holes[bookingId].length;
        const groupedRow = [<tr key={i}>
            <th rowSpan={rowSpan}>{clients[bookings[bookingId].clientId]?.title}</th>
            <td rowSpan={rowSpan}>{find(rooms, {"id": bookings[bookingId].locationId}).name}</td>
            <td rowSpan={rowSpan}>{bookings[bookingId].beginDate.format("L")}</td>
            <td rowSpan={rowSpan}>{bookings[bookingId].endDate ? bookings[bookingId].endDate.format("L") : "?"}</td>
            <td>{`${holes[bookingId][0].beginDate.format("L")} - ${holes[bookingId][0].endDate ? holes[bookingId][0].endDate.format("L") : "?"}`}</td>
        </tr>];
        holes[bookingId].slice(1).forEach((hole, j) =>
            groupedRow.push(<tr key={`${i}${j}`}>
                <td>{`${hole.beginDate.format("L")} - ${hole.endDate ? hole.endDate.format("L") : "?"}`}</td>
            </tr>));

        return groupedRow;
    }, [bookings, clients, holes, rooms]);

    return (
        <div className="card big-shadow" style={{height: "40vh", maxHeight: "40vh", overflowY: "scroll"}}>
            {keys(clients).length === 0 && <DefaultFullPageLoader/>}
            <div className="card-header border-0 pb-0">
                <h5 className="card-title d-flex align-items-center">
                    <span className="text-dark" style={{fontWeight: 600}}>Koppeling periodes</span>
                    <span className="text-muted ms-2" style={{fontSize: ".8rem"}}>Zonder zorglegitimatie</span>
                </h5>
            </div>
            <div className="card-body pt-0" style={{overflowY: "scroll"}}>
                <table className="table table-hover table-sm table-responsive">
                    {keys(holes).length === 0 &&
                    <caption className="text-center">
                        Geen koppeling periodes gevonden zonder zorg legitimaties!
                    </caption>}
                    <thead>
                    <tr className="text-muted" style={{fontWeight: 600}}>
                        <th style={{width: "30%"}}>Client</th>
                        <th style={{width: "25%"}}>Kamer</th>
                        <th style={{width: "15%"}}>Begindatum koppeling</th>
                        <th style={{width: "15%"}}>Einddatum koppeling</th>
                        <th style={{width: "15%"}}>Ontbrekende legitimatie</th>
                    </tr>
                    </thead>
                    <tbody>
                    {keys(clients).length !== 0 &&
                    keys(holes)
                        .sort((a, b) => {
                            const clientA = clients[bookings[a].clientId].title;
                            const clientB = clients[bookings[b].clientId].title;
                            return clientA.localeCompare(clientB);
                        })
                        .map((bookingId, i) => createHoleRows(bookingId, i)).flat(1)}
                    </tbody>
                </table>
            </div>
        </div>
    );
}